.toc {
  .toc-title {
    font-size: $toc-title-font-size;
    font-weight: bold;
    text-transform: uppercase;
  }

  .toc-content {
    font-size: $toc-content-font-size;

    ul {
      text-indent: -0.85rem;
      list-style: none;

      a:first-child::before {
        content: "|";
        font-weight: bolder;
        margin-right: .5rem;
        color: $single-link-color;
      }

      ul {
        padding-left: .8rem;
      }
    }
  }

  ruby {
    background: $code-background-color;

    rt {
      color: $global-font-secondary-color;
    }
  }
}

#toc-auto {
  .toc-content {
    a {
      position: relative;
      display: block;
      padding: 8px 16px;
      margin-bottom: 4px;
      overflow: hidden;
      font-size: 14px;
      color: var(--color-text-secondary);
      text-decoration: none;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
      border-radius: 6px;
      padding-top: 4px !important;
      padding-bottom: 4px !important;

      &.active,
      &:hover {
        color: var(--color-state-selected-primary-text);
        background-color: var(--color-state-selected-primary-bg);
      }
    }

    ul {
      a {
        &:first-child {
          &::before {
            content: "";
          }
        }
      }
    }
  }
}

#toc-static {
  display: none;
  margin: .8rem 0;

  &[kept=true] {
    display: block;
  }

  .toc-title {
    display: flex;
    justify-content: space-between;
    line-height: 2em;
    padding: 0 .75rem;
    background: $code-background-color;
  }

  .toc-content {
    background-color: $code-background-color;

    >nav>ul {
      margin: 0;
      padding: .4rem 1rem .4rem 1.8rem;
    }
  }

  &.open {
    .toc-title {
      background: $code-background-color;
    }
  }
}