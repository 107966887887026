svg.icon {
  display: inline-block;
  width: 1.25em;
  height: 1em;
  text-align: center;

  path {
    fill: currentColor;
  }
}

img.emoji {
  height: 1em;
  width: 1em;
  margin: 0 0.05em 0 0.1em;
  vertical-align: -0.1em;
}

.links {
  a {
    margin-right: 5px;
  }

  .fa,
  .fas,
  .fab,
  .far,
  svg.icon {
    color: var(--color-text-primary);
    font-size: $h3-size;
  }
}
