.algolia-autocomplete {
  width: 100%;
}

.algolia-autocomplete:focus~.header-search-key-slash {
  display: none !important;
}

.header-search-input {
  width: 100%;
}

.jump-to-suggestions {
  position: absolute !important;

  .dropdown-menu {
    width: 100%;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    border: 0;
    margin-top: 0;

    &:before {
      border: 0;
    }

    .search-empty {
      width: 100%;
      padding: 20px;
      text-align: center;
      color: var(--color-text-secondary);
      font-size: $h5-size;

      .search-query {
        color: var(--color-text-primary);
        font-size: $h5-size;
        font-weight: $font-weight-bold;
      }
    }

    .search-footer {
      color: var(--color-text-secondary);
      text-align: right;
      margin-right: 20px;
      margin-bottom: 10px;
    }

    .suggestions {
      width: 100%;

      .suggestion {
        width: 100%;
        border-bottom: 1px solid var(--color-border-primary);
        display: flex;
        flex-direction: column;
        padding: 20px;
        font-size: 14px;

        &:last-child {
          border-bottom: 0;
        }

        .suggestion-row {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-bottom: 8px;
        }

        .suggestion-title {
          color: var(--color-text-primary);
          font-size: $h4-size;
          font-weight: $font-weight-bold;
        }

        .suggestion-date {
          font-size: $h5-size;
          color: var(--color-text-secondary);
        }

        .suggestion-context {
          font-size: $h5-size;
          color: var(--color-text-secondary);
        }

        &.cursor,
        &:hover {
          background: var(--color-bg-info-inverse);

          .suggestion-title {
            color: var(--color-text-white);
          }

          .suggestion-date {
            color: var(--color-text-white);
          }

          .suggestion-context {
            color: var(--color-text-white);
          }
        }
      }
    }
  }
}