@include ms;
@include link(true, true);

@import "../_partial/mask";
@import "../_partial/icon";
@import "../_partial/details";
@import "../_partial/fixed-button";
@import "../_partial/cookieconsent";

img {
  @include object-fit(contain);
}