.page {
  position: relative;
  width: 100%;
  margin: 0 auto;
  @include blur;
}

@import "_single";
@import "_special";
@import "_archive";
@import "_home";
@import "_404";